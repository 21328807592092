'use client'
import { useEffect } from 'react'
import { Crisp } from 'crisp-sdk-web'

const CRISP_WEBSITE_ID = 'af9adec5-ddfa-4db9-a4a3-25769daf2fc2'

export default function CrispChat() {
  useEffect(() => {
    Crisp.configure(CRISP_WEBSITE_ID)
    Crisp.session.setSegments(['stellate'], true)
  })

  return null
}
